<template>
  <title-wrapper :title="$t('components.manageUsers')">
    <v-container class="white pa-6">
      <v-data-table
        :headers="headers"
        :items="users"
        sort-by="role"
        :header-props="tableHeaderProps"
        class="elevation-0"
        :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
      >
        <template v-slot:[`item.role`]="{ item }">{{ $t(`statics.roles.${item.role}`) }}</template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on }">
              <v-icon small color="black" class="mr-2" v-on="on"> mdi-dots-vertical </v-icon>
            </template>
            <v-card>
              <router-link :to="{ name: 'UserDetails', params: { userId: item._id } }">
                <v-list-item class="hover-item-popup">
                  <v-list-item-content>
                    {{ $t('helpers.showDetails') }}
                  </v-list-item-content>
                </v-list-item>
              </router-link>
              <router-link :to="{ name: 'EditUser', params: { userId: item._id } }">
                <v-list-item class="hover-item-popup">
                  <v-list-item-content>
                    {{ $t('helpers.removeUser') }}
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>
      <primary-button color="primary" :to="{ name: 'AddUser' }"
        ><v-icon left> mdi-plus-circle-outline </v-icon>{{ $t('helpers.addUser') }}</primary-button
      >
    </v-container>
  </title-wrapper>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import CompanyService from '../../../services/CompanyService';
import TitleWrapper from '../../../components/TitleWrapper.vue';
import { userDataComputed } from '../../../store/helper';
import { tableHeaderProps } from '../../../mixins/tableHeaderProps';

export default {
  components: { PrimaryButton, TitleWrapper },
  mixins: [tableHeaderProps],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    users: [],
    editedIndex: -1,
  }),

  computed: {
    ...userDataComputed,
    headers() {
      return [
        {
          text: this.$i18n.t('helpers.fullName'),
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: this.$i18n.t('helpers.phoneNumber'), value: 'phone' },
        { text: this.$i18n.t('helpers.email'), value: 'email' },
        { text: this.$i18n.t('helpers.userRights'), value: 'role' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const response = await CompanyService.getAllCompanyUsers(this.userData?.companyId);
      this.users = response.data.map((user) => {
        return {
          name: user.firstName + ' ' + user.lastName,
          ...user,
        };
      });
    },
  },
};
</script>
