<template>
  <title-link-wrapper
    :title="$t('components.userDetails-ByUser.title')"
    :to="{ name: 'ManageUsers' }"
  >
    <v-container class="white pa-8">
      <v-row class="mb-6">
        <h2 class="text font-weight-regular">{{ $t('helpers.userDetails') }}</h2>
      </v-row>

      <v-row v-if="loading" class="mb-6">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-row>
      <v-row v-else> <v-divider class="mb-6"></v-divider></v-row>
      <v-form v-if="!loading" v-model="isFormValid" ref="form">
        <v-row>
          <v-col class="pa-0" cols="5">
            <h2 class="text mb-3">{{ $t('helpers.firstName') }}</h2>
            <p>{{ user.firstName || '' }}</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="pa-0" cols="6">
            <h2 class="text mb-3">{{ $t('helpers.lastName') }}</h2>
            <p>{{ user.lastName || '' }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.phoneNumber') }}</h2>
            <p>{{ user.phone || '' }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.email') }}</h2>
            <p>{{ user.email || '' }}</p>
          </v-col>
        </v-row>
        <v-row> <v-divider class="mb-6"></v-divider></v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.typeOfRights') }}</h2>
            <p>{{ getUserRoleName() || '' }}</p>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <primary-button
            :to="{ name: 'EditUser', params: { userId: user._id } }"
            outlined
            childclass="mt-2"
            >{{ $t('components.userDetails-ByUser.editUser') }}</primary-button
          >
        </v-row>
      </v-form>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import { validators } from '../../../validators/Validators';
import { roles, rolesFunc } from '../../../static/roles';
import UserService from '../../../services/UserService';
import { userDataComputed } from '../../../store/helper';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import TitleLinkWrapper from '../../../components/TitleLinkWrapper.vue';
import { formMixin } from '../../../mixins/formMixin';

export default {
  name: 'UserDetails',
  props: ['backLink', 'userRoles'],
  mixins: [formMixin],
  data() {
    return {
      user: null,
      isFormValid: false,
      loading: true,
    };
  },
  components: { PrimaryButton, TitleLinkWrapper },
  computed: {
    validator() {
      return validators;
    },
    ...userDataComputed,
  },
  methods: {
    getUserRoleName() {
      return Object.values(rolesFunc()).find((item) => item.value === this.user.role)?.text;
    },
    isAdmin() {
      return this.userData.role === roles.SYSADMIN;
    },
  },
  async created() {
    const userId = this.$route.params.userId;
    try {
      this.loading = true;
      const res = await UserService.getUser(userId);
      this.user = res.data;
    } catch (err) {
      console.error(err);
      this.$toasted.global.error({
        message: this.$i18n.t('components.userDetails-ByUser.createdFunc'),
      });
      this.$router.push({ name: this.backLink });
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped></style>
